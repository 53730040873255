/* RESPONSIBLE TEAM: team-help-desk-experience */
import { tracked } from '@glimmer/tracking';
import type InboxApi from 'embercom/services/inbox-api';
import { inject as service } from '@ember/service';
import { Resource } from 'ember-resources/core';
import { type Named } from 'ember-resources/core/types';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import { registerDestructor } from '@ember/destroyable';
import type Conversation from 'embercom/objects/inbox/conversation';
import type ApplicationInstance from '@ember/application/instance';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';

type Args = {
  conversation: Conversation | null | undefined;
};

interface LinkedTicketsData {
  tickets: LinkedTicketSummary[];
  totalCount: number;
}

/* eslint-disable @intercom/intercom/no-component-inheritance */
class LinkedTicketsResource extends Resource<Named<Args>> {
  @service declare inboxApi: InboxApi;
  @tracked declare linkedTicketsData: LinkedTicketsData | null;

  declare conversationId: number;
  protected lastFetchedId?: number;
  protected lastReloadTime = 0;
  protected readonly DEBOUNCE_INTERVAL = 500; // 500ms debounce

  constructor(owner: ApplicationInstance) {
    super(owner);

    registerDestructor(this, () => {
      taskFor(this.fetchLinkedTickets).cancelAll();
    });
  }

  modify(_: unknown[], args: Args) {
    // Handle case where conversation is undefined or null
    if (!args.conversation) {
      this.linkedTicketsData = null;
      return;
    }

    let conversationId = args.conversation.id;
    if (conversationId === this.conversationId) {
      return;
    }

    this.conversationId = conversationId;

    // Only fetch if we haven't fetched this conversation's tickets before
    if (this.lastFetchedId !== this.conversationId) {
      this.linkedTicketsData = null;
      taskFor(this.fetchLinkedTickets).perform(this.conversationId);
    }
  }

  get tickets() {
    return this.linkedTicketsData?.tickets ?? [];
  }

  get totalCount() {
    return this.linkedTicketsData?.totalCount ?? 0;
  }

  get isLoading() {
    return taskFor(this.fetchLinkedTickets).isRunning;
  }

  private shouldDebounce(): boolean {
    let now = Date.now();
    if (now - this.lastReloadTime < this.DEBOUNCE_INTERVAL) {
      return true;
    }
    this.lastReloadTime = now;
    return false;
  }

  reload() {
    if (this.isLoading || this.shouldDebounce()) {
      return;
    }
    this.lastFetchedId = undefined;
    taskFor(this.fetchLinkedTickets).perform(this.conversationId);
  }

  safeReload() {
    if (this.isLoading || this.shouldDebounce()) {
      return;
    }
    taskFor(this.fetchLinkedTickets).perform(this.conversationId);
  }

  @task({ restartable: true })
  *fetchLinkedTickets(id: number): TaskGenerator<void> {
    // If we've already fetched this conversation's tickets, don't fetch again
    if (this.lastFetchedId === id) {
      return;
    }

    let controller = new AbortController();
    let data;

    try {
      data = yield this.inboxApi.getLinkedTickets(id, { signal: controller.signal });
      // Only update lastFetchedId if the fetch was successful
      this.lastFetchedId = id;
    } catch (error) {
      // Only throw if it's not an abort error
      if (error.name !== 'AbortError') {
        throw error;
      }
      return;
    } finally {
      // Only abort if the task is still running
      if (!controller.signal.aborted) {
        controller.abort();
      }
    }

    // the task should be cancelled if the conversation has changed
    // but double check to be safe
    if (this.conversationId !== id) {
      return;
    }

    this.linkedTicketsData = data;
  }
}

export default LinkedTicketsResource;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LinkedTicketsResource': typeof LinkedTicketsResource;
    'inbox2/linked-tickets-resource': typeof LinkedTicketsResource;
  }
}
