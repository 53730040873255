/* import __COLOCATED_TEMPLATE__ from './unified-set-away-reason.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import type AdminAwayService from 'embercom/services/admin-away-service';
// @ts-ignore
import { type AwayStatusReason } from 'embercom/objects/inbox/away-reason';
import { tracked } from 'tracked-built-ins';

interface Args {
  item: {
    text: string;
    value?: AwayStatusReason;
    awayModeEnabled: boolean;
  };
}

interface Signature {
  Args: Args;
}

export default class UnifiedSetAwayReason extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare adminAwayService: AdminAwayService;

  @tracked searchQuery = '';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.adminAwayService.setup();
  }

  get awayReasons() {
    return this.adminAwayService.awayStatusReasons.filter((reason) => {
      return reason.id === this.currentSelectedReasonId || !reason.deleted;
    });
  }

  get filteredAwayReasons() {
    return this.awayReasons.filter((reason) => {
      return (
        reason.label.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        reason.id === this.currentSelectedReasonId
      );
    });
  }

  get searchFieldVisible() {
    return this.awayReasons.length > 7;
  }

  get currentSelectedReasonId() {
    return this.args.item.value?.id;
  }

  get isAwayReasonMandatory() {
    return this.session.workspace.adminAwayReasonIsMandatory;
  }

  @action setAwayReason(reason: AwayStatusReason | null = null) {
    this.adminAwayService.setAdminAsAway(reason);
  }

  @action setActive() {
    this.adminAwayService.setAdminAsAvailable();
  }

  // This function is a Popper.js modifier (https://popper.js.org/docs/v2/modifiers/)
  // that keeps the dimensions of the app switcher popover the same as those of
  // the primary nav menu popover. This behavior is a design requirement from
  // the "early 2018" brand refresh.
  matchPopoverDimensionsToMenuPopover(popperModifierData: any) {
    let { reference: referenceElement, popper: popperElement } = popperModifierData.state.elements;
    let referenceElementPopoverContainer = referenceElement.closest('[data-popover-content]');
    popperElement.style.width = `${referenceElementPopoverContainer.offsetWidth}px`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::UnifiedSetAwayReason': typeof UnifiedSetAwayReason;
    'inbox2/user-menu/unified-set-away-reason': typeof UnifiedSetAwayReason;
  }
}
