/* import __COLOCATED_TEMPLATE__ from './reassign.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';

interface Signature {
  Args: any;
}

export default class Reassign extends Component<Signature> {
  @service declare session: Session;

  get isUsingUnifiedAwayStatusReasons() {
    return this.session.workspace.adminAwayReasonIsMandatory;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::Reassign': typeof Reassign;
    'inbox2/user-menu/reassign': typeof Reassign;
  }
}
